import { breakpoints, colors } from '../variables'

import Anchor from './anchor'
import Button from './button'
import Line from './line'
import PhoneNumber from './phone-number'
import React from 'react'
import Subtitle from './subtitle'
import Wrapper from './wrapper'
import { css } from '@emotion/react'

const Intro = React.memo(() => (
  <div
    css={css`
      text-align: center;
    `}
    id="praktisch"
  >
    <h2
      css={css`
        margin-bottom: 0.1em;
      `}
    >
      Praktische informatie
    </h2>
    <Subtitle
      className={css`
        margin-bottom: 2rem;
      `}
    >
      Alles wat je wil weten over je reis naar Tonga
    </Subtitle>
    <Line
      className={css`
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: ${breakpoints.tablet}px) {
          margin-bottom: 3rem;
        }
      `}
    />
    <p
      css={css`
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: ${breakpoints.tablet}px) {
          margin-bottom: 3rem;
        }
      `}
    >
      Dit is een bijzondere reis die door onze kennis van al deze plekken, voor
      jou bijna geen voorbereiding vergt. Wij regelen het en garanderen een
      ultieme reiservaring met optimale verbindingen en die goed op jouw wensen
      is afgestemd.
    </p>
  </div>
))

const size = 15
const line = 3
const Icon = React.memo(({ toggled }) => (
  <div
    css={css`
      flex: none;
      height: ${size}px;
      margin-left: 20px;
      position: relative;
      width: ${size}px;
    `}
  >
    <div
      css={css`
        background: ${colors.blue75};
        height: ${line}px;
        left: 0;
        position: absolute;
        top: ${(size - line) / 2}px;
        width: 100%;
      `}
    />
    {!toggled && (
      <div
        css={css`
          background: ${colors.blue75};
          width: ${line}px;
          top: 0;
          position: absolute;
          left: ${(size - line) / 2}px;
          height: 100%;
        `}
      />
    )}
  </div>
))

class Faq extends React.PureComponent {
  state = { toggled: false }

  click = () => {
    this.setState({ toggled: !this.state.toggled })
  }

  render() {
    return (
      <div
        css={css`
          border-radius: 5px;
          box-shadow: 0 0 15px ${colors.shadow};
          cursor: pointer;
          padding: 1.25rem;
          user-select: none;

          @media (min-width: ${breakpoints.small}px) {
            padding: 1.5rem 1.7rem;
          }
        `}
        onClick={this.click}
      >
        <div
          css={css`
            align-items: center;
            display: flex;
            justify-content: space-between;
          `}
        >
          <h6
            css={css`
              margin-bottom: 0;
            `}
          >
            {this.props.question}
          </h6>
          <Icon toggled={this.state.toggled} />
        </div>
        {this.state.toggled && (
          <p
            css={css`
              font-size: 0.9rem;
              margin: 1.5em 0 0.2rem;
            `}
          >
            {this.props.answer}
          </p>
        )}
      </div>
    )
  }
}

const Contact = React.memo(() => (
  <div
    css={css`
      @media (min-width: ${breakpoints.laptop}px) {
        display: flex;
        justify-content: space-between;
      }
    `}
  >
    <div
      css={css`
        @media (min-width: ${breakpoints.laptop}px) {
          flex: none;
          width: 28.5%;
        }
      `}
    >
      <h3
        css={css`
          margin-bottom: 0.3em;
        `}
      >
        Heb je een andere vraag?
      </h3>
      <Subtitle
        className={css`
          font-size: 1.33rem;
          margin-bottom: 1.1rem;

          @media (min-width: ${breakpoints.tablet}px) {
            font-size: 1.33rem;
          }
        `}
      >
        We helpen je graag!
      </Subtitle>
      <Line
        className={css`
          @media (min-width: ${breakpoints.laptop}px) {
            margin-bottom: 0;
          }
        `}
        small={true}
      />
    </div>
    <div
      css={css`
        @media (min-width: ${breakpoints.laptop}px) {
          flex: none;
          width: 64.5%;
        }
      `}
    >
      <p>Stuur ons een e-mail of bel met een van onze Voja travel experts.</p>
      <div
        css={css`
          @media (min-width: ${breakpoints.small}px) {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
          }
        `}
      >
        <Anchor target="#contact">
          <Button arrow="down">Contact</Button>
        </Anchor>
        <PhoneNumber iconColor="lightBlue" />
      </div>
    </div>
  </div>
))

export default React.memo(() => (
  <Wrapper
    className={css`
      margin-bottom: 4rem;

      @media (min-width: ${breakpoints.tablet}px) {
        margin-bottom: 5.5rem;
      }

      @media (min-width: ${breakpoints.laptop}px) {
        margin-bottom: 7.25rem;
      }

      margin-left: auto;
      margin-right: auto;
      max-width: ${840 + 80}px;
    `}
  >
    <Intro />
    <div
      css={css`
        margin-bottom: 3rem;

        @media (min-width: ${breakpoints.tablet}px) {
          margin-bottom: 4.5rem;
        }

        > div:not(:last-child) {
          margin-bottom: 1.1rem;
        }
      `}
    >
      <Faq
        question="Hoe verloopt het zwemmen met walvissen?"
        answer="Walvissen zijn wilde dieren en komen uit Antarctica naar Tonga gezwommen.
        Je gaat op pad met een speciale privé excursieboot met max 8 opvarenden. Je krijgt een wetsuit, snorkelmasker
        en gaat met een gids en maximaal 3 anderen het water in op circa 100 meter afstand van de walvis(sen).
        Walvissen blijven erg voorzichtig met ons mensen, soms wel 400 keer kleiner dan zij zijn. Er zijn ons geen
        verhalen bekend van ongelukken. Het risico is natuurlijk niet 0. De walvissen maken visueel contact en met
        geluid. Het vertrouwen groeit vaak vanzelf, vooral de jongeren zijn nieuwsgierig en komen na verloop van
        tijd dichtbij."
      />
      <Faq
        question="Hoe zit het met de maaltijden op locatie?"
        answer="Op Tonga is ontbijt overal inbegrepen en lunch op de walvisdag. Op Fiji zijn op de 'cast
        away' eilanden ontbijt, lunch en diner inbegrepen en op de overige plekken alleen ontbijt."
      />
      <Faq
        question="Moet ik visa regelen? "
        answer="Nee dat is niet nodig. Je kunt met een Nederlands of Belgisch paspoort kosteloos alle
        landen op het reisplan bezoeken."
      />
      <Faq
        question="Heb ik inentingen nodig?"
        answer="De meeste vaccinaties zitten al in het Rijksvaccinatieprogramma en heb je als het goed is al gehad. Wat aanbevolen is als je deze vaccinaties nog niet eerder had zijn Buiktyfus en Hepatitis A."
      />
      <Faq
        question="Hoe zit het met de maaltijden op de vluchten?"
        answer="Op de intercontinentale vluchten werken we alleen met Premium Airlines en zijn de maaltijden en drankjes inbegrepen."
      />
      <Faq
        question="Hoeveel bagage gaat mee?"
        answer="Op alle vluchten is 20kg ruimbagage en 10 kg handbagage per persoon inbegrepen."
      />
    </div>
    <Contact />
  </Wrapper>
))
